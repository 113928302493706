import React,{Component} from 'react';

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from '@material-ui/core/Typography';

import Logo from '../../assets/imgs/deenconnect-masjid-umme-hani-ra-florida.svg';
import DcLogo from '../../assets/imgs/dc_logo.png';
import DCLogoLarge from '../../assets/imgs/DC_Logo_large.png';
import './style.css'


  class Comingsoon extends Component{
      
        render(){

           
            return(
                <div className="main_container">
                    <div className="left_container">
                    <div className="left_container_inner">

                        <div className="header">
                                {/* <img src={Logo} alt="Logo" className="logo"/> */}

                                <h1 className="title_h1">Welcome to <span>Masjid Ditmas Park</span></h1>

                                <p className="p_text">Alhamdulillah, we have partnered with DEENCONNECT to build a website that’s secured,
                                easy to use, mobile friendly, enriched with amazing and helpful features and much more. And we are confident 
                                you will love it, insha’Allah.</p>


                                <p className="p_text"> In the meantime, our secured donation form is superbly ready as we continuously need your 
                                generous donations. Please click on the button below to support us.</p>


                                <a className="donate_btn" href="https://give.deenfund.com/ditmas-park-masjid" target="_blank">I want to donate</a>

                                <p className='p_slam'>With Salam - <span>Team @ Masjid Ditmas Park</span></p>
                                
                                 <div className="footer dekstop_view">
                                    <span>POWERED BY</span> <a href="https://deenconnect.io/" target="-blanck"><img src={DcLogo} alt="Dc Logo" className="dcLogo"/></a>
                                </div> 

                        </div>
                        </div>
                        
                    </div>



                    <div className="right_container">

                        <div className="right_container_inner">

                        <div className="logo_deenconnect">
                            <img src={DCLogoLarge} alt="Dc Logo" className="dcLogoLarge"/>
                        </div> 

                        <div className="accordion_container">

                            <ExpansionPanel>
                                <ExpansionPanelSummary
                                expandIcon={
                                <ExpandMoreIcon />
                                }
                                aria-controls="panel1bh-content" id="panel1bh-header">
                                    <Typography className="accTitle"> What is DEENCONNECT?</Typography>
                                    </ExpansionPanelSummary><ExpansionPanelDetails>
                                    <Typography className="accDate">DEENCONNECT is an all-in-one platform for organizations of all sizes to engage members and visitors, and manage day-to-day activities.</Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>

                         <ExpansionPanel>
                            <ExpansionPanelSummary
                            expandIcon={
                                <ExpandMoreIcon />
                            }
                            aria-controls="panel1bh-content" id="panel1bh-header">
                                <Typography className="accTitle"> Who uses DEENCONNECT?</Typography>
                                    </ExpansionPanelSummary><ExpansionPanelDetails>
                                    <Typography className="accDate">Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your organization and Masjid.</Typography>
                                </ExpansionPanelDetails>
                         </ExpansionPanel>

                         <ExpansionPanel>
                            <ExpansionPanelSummary
                            expandIcon={
                                <ExpandMoreIcon />
                            }
                            aria-controls="panel1bh-content" id="panel1bh-header">
                                <Typography className="accTitle">How do I contact?</Typography>
                                    </ExpansionPanelSummary><ExpansionPanelDetails>
                                    <Typography className="accDate">Glad you asked. Whether you want to refer an organization/Masjid to us, 
                                    find out more about what we do, our upcoming services, best way is to visit our website - <a href="https://deenconnect.io/" target="-blanck">deenconnect.io </a> . And if you need bit more attention from us, our direct contact details are also shared.</Typography>
                                </ExpansionPanelDetails>
                         </ExpansionPanel>


                         
                        </div>
                        <div className=" mobile_view">
                            <div className="footer">
                                <span>POWERED BY</span> <a href="https://deenconnect.io/" target="-blanck"><img src={DcLogo} alt="Dc Logo" className="dcLogo"/></a>
                            </div>
                        </div>

                    </div>
                    </div>
                </div>
        );
      }
  }


export default Comingsoon;